.edit-btn {
    font-weight: bold;
    font-size: 16px;
    border: none;
    line-height: 20px;
    background-color: #00baf2;
    color: #fff;
    padding: 10px 20px;
    margin-right: 20px;
    cursor: pointer;
    width: 20%;
}
.user-detail {
    text-transform: capitalize;
    padding: 10px 0;
    margin: 10px 0;
    color: #000;
}
.user-first { 
    text-align: center;
    margin: 6px 0 10px;
    font-size: 36px;
}
.user-name {
    color: #fff;
}
.user-wrapper {
    background: #fff;
    color: #000;
    margin: 30px 0;
    display: inline-block;
    width: 100%;
}
.user-wrapper-profile{
    background: #f3f3f3;
}
.personal-information {
    width: 100%;
    padding: 30px;
    
   
}
.personal-daital{
     max-width: 800px;
    padding: 30px;
    margin: 30px;
    background: #FFF;
box-shadow: 0px 2px 3px #0029700D;
border: 1px solid #00297024;
border-radius: 10px;
opacity: 1;
}
.user-welcome {
    color: var(--white);
    font-weight: 100;   
}
.user-details-wrapper {
    width: 450px;
}
.user-detail .field-wrapper {
    display: inline-block;
    width: 100%;
}
.user-detail .form-field {
    display: inline-block;
    width: 49%;
    margin-bottom: 10px;
    max-width: 300px;
}
.user-detail label.detail-key {
    display: block;
    color: #00b4ec;
    font-size: 14px;
}
.user-detail input.detail-value {
border: none;
padding: 0 10px;
background: #F4F8F8;
height: 35px;
border-radius: 5px;
min-width: 250px;


}
.user-detail input.detail-value:focus{
    box-shadow: 0px 1px 2px #00297040;
border: 1px solid #8B9CA8;
}
.card-info {
    margin: 10px 0 20px;
}
.hide {
    display: none;
}

@media only screen and (max-width: 767px){
 
  .personal-information{
    width: 100%;
    border-left: none;
  }
  .user-welcome{
    font-size: 20px;
  }
  .user-name{
    font-size: 22px;
  }
  }


  
@media only screen and (max-width: 650px){
    .user-detail .form-field{
        display: block;
        width: 260px;
        margin: 10px auto;
        
    }
    .user-detail .form-field input{
        margin: auto;
    }
}